<template>
  <z-modal
    :show="show"
    centered
    size="md"
    body-class="p-4 rounded"
    no-close-on-backdrop
    @hide="hideModal"
  >
    <div class="text-2xl font-weight-bold">Confirmación de mensaje</div>
    <div class="text-sm mb-4">
      {{ message }}
    </div>
    <z-button
      class="w-100"
      :disabled="loading"
      :loading="loading"
      @click="sendMessages"
    >
      Enviar
    </z-button>
  </z-modal>
</template>

<script>
import ZModal from "@zubut/common/src/components/ZModal";

const moduleName = "drivers";

export default {
  name: "DriversMessageModalConfirmation",

  components: {
    ZModal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    /**
     * Object that indicates that a single driver will be notified
     */
    driver: {
      type: Object,
      default: null
    },
    /**
     * Notification to send to drivers
     */
    notification: {
      type: Object,
      required: true
    },
    /**
     * Scheduled date for notification
     */
    scheduledDate: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    totalDrivers() {
      return this.$store.getters[`${moduleName}/getTotalSelectedDrivers`];
    },

    message() {
      if (this.driver) {
        return `Se enviará una notificación a ${this.driver?.name ||
          " este mensajero"}`;
      }
      return `Se enviará una notificación a ${this.totalDrivers} mensajeros`;
    }
  },

  methods: {
    hideModal() {
      this.$emit("update:show", false);
      this.$emit("hide");
    },
    sendMessages() {
      this.loading = true;
      this.$store
        .dispatch("drivers/sendMessages", {
          driver: this.driver,
          notification: this.notification,
          when: this.scheduledDate
        })
        .then(() => {
          this.loading = false;
          this.$emit("done");
        })
        .catch(err => {
          this.$emit("error", err);
        });
    }
  }
};
</script>
