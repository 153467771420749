<template>
  <div>
    <quick-message
      :message="quickMessage ? quickMessage.message : ''"
      :show.sync="showQuickMessage"
      :type="quickMessage ? quickMessage.type : 'success'"
      with-icon
      class="mt-4 mb-2"
    />
    <z-modal
      :show="show"
      centered
      size="md"
      body-class="p-4 rounded"
      @hide="hideModal"
    >
      <div class="text-2xl font-weight-bold">Enviar mensaje</div>
      <div class="text-sm mb-4">
        Se enviará una notificación a sus celulares
      </div>
      <z-input v-model="title" label="Título" class="mb-3" />
      <z-textarea
        v-model="message"
        label="Mensaje"
        class="mb-3"
        maxlength="120"
      />
      <div class="font-weight-bold mb-1">Programar para</div>
      <div class="row">
        <div class="col">
          <z-date-picker
            v-model="date"
            calendar-class="date-picker"
            :disabled-dates="disabledDates"
            input-class="form-control bg-white w-100"
            placeholder="Fecha"
          />
        </div>
        <div class="col">
          <z-dropdown
            v-model="time"
            :options="timeOptions"
            variant="white"
            class="w-100 rounded"
            max-height="200px"
            placeholder="Hora"
            :show-header="false"
          />
        </div>
      </div>
      <div v-show="showPreview">
        <hr class="my-3" />
        <div class="text-sm text-dim-gray font-weight-semi-bold mb-3">
          Visualizador
        </div>
        <div class="text-sm font-weight-bold mb-1">{{ title }}</div>
        <div class="text-sm">{{ message }}</div>
      </div>
      <z-button
        class="w-100 mt-4"
        :disabled="!enableSend"
        @click="showConfirmation"
      >
        Confirmar
      </z-button>
    </z-modal>
    <drivers-message-modal-confirmation
      :show.sync="showConfirmationModal"
      :driver="driver"
      :notification="notification"
      :scheduled-date="scheduledDate"
      @done="handleDone"
      @hide="showModal"
      @error="handleError"
    ></drivers-message-modal-confirmation>
  </div>
</template>

<script>
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZModal from "@zubut/common/src/components/ZModal";
import ZTextarea from "@zubut/common/src/components/ZTextarea";
import { formatToISO } from "@zubut/common/src/utils/time";
import DriversMessageModalConfirmation from "./DriversMessageModalConfirmation";
import {
  addHours,
  subDays,
  endOfDay,
  getHours,
  isSameDay,
  isAfter,
  set
} from "date-fns";

export default {
  name: "DriversMessageModal",

  components: {
    DriversMessageModalConfirmation,
    ZDatePicker,
    ZDropdown,
    ZModal,
    ZTextarea
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    /**
     * Object that indicates that a single driver will be notified
     */
    driver: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      title: "",
      message: "",
      date: new Date(),
      disabledDates: { to: endOfDay(subDays(new Date(), 1)) },
      time: getHours(addHours(new Date(), 1)),
      showConfirmationModal: false,
      quickMessage: null,
      showQuickMessage: false
    };
  },

  computed: {
    timeOptions() {
      const res = [];
      for (let i = 0; i <= 23; i++) {
        let meridiem = i >= 12 ? "pm" : "am";
        let hour = i > 12 ? i - 12 : i;

        /* Don't allow past time to be selected */
        if (
          !isSameDay(new Date(), this.date) ||
          (isSameDay(new Date(), this.date) && i > getHours(this.date))
        ) {
          res.push({ value: i, text: `${hour}:00 ${meridiem}` });

          if (i !== 23) {
            res.push({ value: i + 0.5, text: `${hour}:30 ${meridiem}` });
          }
        }
      }
      return res;
    },
    showPreview() {
      return this.title || this.message;
    },
    enableSend() {
      return this.title && this.message && this.isValidDate();
    },
    notification() {
      return {
        title: this.title,
        message: this.message
      };
    },
    scheduledDate() {
      return formatToISO(
        set(this.date, {
          hours: Math.trunc(this.time),
          minutes: this.time % 1 ? 30 : 0,
          seconds: 0
        })
      );
    }
  },

  watch: {
    date() {
      if (
        isSameDay(new Date(), this.date) &&
        this.time < getHours(new Date())
      ) {
        this.time = getHours(addHours(new Date(), 1));
      }
    },
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    }
  },

  methods: {
    hideModal() {
      this.$emit("update:show", false);
    },
    showModal() {
      this.$emit("update:show", true);
    },
    showConfirmation() {
      this.showConfirmationModal = true;
      this.$emit("update:show", false);
    },
    isValidDate() {
      if (isSameDay(this.date, new Date())) {
        return this.time > getHours(new Date());
      }
      return isAfter(this.date, new Date());
    },
    resetForm() {
      this.message = "";
      this.title = "";
      this.date = new Date();
      this.time = getHours(addHours(new Date(), 1));
    },
    handleDone() {
      this.showConfirmationModal = false;
      this.resetForm();
      this.quickMessage = {
        message: "Notificación agendada",
        type: "success"
      };
      this.hideModalDelayed();
    },
    handleError(error) {
      this.showConfirmationModal = false;
      this.resetForm();
      this.quickMessage = {
        message: error.message || "Error al intentar agendar la notificación",
        type: "error"
      };
      this.hideModalDelayed();
    },
    hideModalDelayed() {
      /* This hides the modal after message is sent, since closing the confirmation opens this modal */
      setTimeout(() => {
        this.hideModal();
      }, 200);
    }
  }
};
</script>
