var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"z-textarea",class:{
    'z-textarea-has-value': _vm.hasValue,
    'z-textarea-append-icon': _vm.appendIcon,
    'z-textarea-prepend-icon': _vm.prependIcon,
    'z-textarea-valid': _vm.isStateValid,
    'z-textarea-invalid': _vm.isStateInvalid,
    'z-textarea-label-static': _vm.labelVariant === 'static'
  }},[(_vm.prependIcon)?_c('z-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.prependIconTootltip),expression:"prependIconTootltip",modifiers:{"top":true}}],staticClass:"prepend-icon",attrs:{"name":_vm.prependIcon,"variant":_vm.prependIconVariant,"role":"button","size":"20","data-test-id":"prepend-icon"},nativeOn:{"click":function($event){return _vm.$emit('prepend-icon-click', $event)}}}):_vm._e(),_c('textarea',_vm._b({ref:"input",class:_vm.disabled ? 'disabled' : null,attrs:{"id":_vm.id,"type":_vm.type,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"cols":_vm.cols,"rows":_vm.rows},domProps:{"value":_vm.value},on:{"input":_vm.handleInput,"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"keydown":function($event){return _vm.$emit('keydown', $event)}}},'textarea',_vm.$attrs,false)),(_vm.label || _vm.$scopedSlots['label'])?_c('label',{class:_vm.labelVariant,attrs:{"for":_vm.id}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2):_vm._e(),(_vm.maxlength)?_c('span',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.value.length)+" / "+_vm._s(_vm.maxlength)+" ")]):_vm._e(),(_vm.isStateValid)?_c('z-icon',{staticClass:"state-icon",attrs:{"name":"Check","size":"20","color":"#0a937f","data-test-id":"state-valid-icon"}}):_vm._e(),(_vm.appendIcon)?_c('z-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.appendIconTootltip),expression:"appendIconTootltip",modifiers:{"top":true}}],staticClass:"append-icon",attrs:{"name":_vm.appendIcon,"variant":_vm.appendIconVariant,"role":"button","size":"20","data-test-id":"append-icon"},nativeOn:{"click":function($event){return _vm.$emit('append-icon-click', $event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }