<template>
  <div
    class="z-textarea"
    :class="{
      'z-textarea-has-value': hasValue,
      'z-textarea-append-icon': appendIcon,
      'z-textarea-prepend-icon': prependIcon,
      'z-textarea-valid': isStateValid,
      'z-textarea-invalid': isStateInvalid,
      'z-textarea-label-static': labelVariant === 'static'
    }"
  >
    <z-icon
      v-if="prependIcon"
      :name="prependIcon"
      :variant="prependIconVariant"
      role="button"
      class="prepend-icon"
      size="20"
      data-test-id="prepend-icon"
      v-tooltip.top="prependIconTootltip"
      @click.native="$emit('prepend-icon-click', $event)"
    />
    <textarea
      :id="id"
      :type="type"
      :value="value"
      :disabled="disabled"
      @input="handleInput"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @keydown="$emit('keydown', $event)"
      v-bind="$attrs"
      :class="disabled ? 'disabled' : null"
      ref="input"
      :maxlength="maxlength"
      :cols="cols"
      :rows="rows"
    >
    </textarea>
    <label
      v-if="label || $scopedSlots['label']"
      :for="id"
      :class="labelVariant"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <span v-if="maxlength" class="word-count">
      {{ value.length }} / {{ maxlength }}
    </span>
    <z-icon
      v-if="isStateValid"
      name="Check"
      size="20"
      class="state-icon"
      color="#0a937f"
      data-test-id="state-valid-icon"
    />
    <z-icon
      v-if="appendIcon"
      :name="appendIcon"
      :variant="appendIconVariant"
      role="button"
      class="append-icon"
      size="20"
      data-test-id="append-icon"
      v-tooltip.top="appendIconTootltip"
      @click.native="$emit('append-icon-click', $event)"
    />
  </div>
</template>

<script>
import ZIcon from "./ZIcon.vue";
import { formatPhone } from "../utils/strings";

export default {
  name: "ZTextarea",

  components: {
    ZIcon
  },

  props: {
    appendIcon: {
      type: String,
      default: null
    },
    appendIconVariant: {
      type: String,
      default: "normal",
      validator: val => ["normal", "active"].indexOf(val) > -1
    },
    appendIconTootltip: {
      type: String,
      default: ""
    },
    cols: {
      type: [String, Number],
      default: 50
    },
    prependIcon: {
      type: String,
      default: null
    },
    prependIconVariant: {
      type: String,
      default: "normal",
      validator: val => ["normal", "active"].indexOf(val) > -1
    },
    prependIconTootltip: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    labelVariant: {
      type: String,
      default: "animated",
      validator: val => ["animated", "static"].indexOf(val) > -1
    },
    maxlength: {
      type: [String, Number],
      default: null
    },
    rows: {
      type: [String, Number],
      default: 4
    },
    state: {
      type: Boolean,
      default: null
    },
    type: {
      type: String,
      default: "text",
      validator: val =>
        ["text", "tel", "email", "password", "number"].indexOf(val) > -1
    },
    value: {
      type: [Number, String],
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasValue() {
      return this.value?.length > 0;
    },
    isStateValid() {
      return this.state === true;
    },
    isStateInvalid() {
      return this.state === false;
    }
  },

  methods: {
    handleInput(event) {
      const value = event.target.value;
      if (this.type === "tel") {
        const formattedNumner = formatPhone(value);
        this.$emit("input", formattedNumner);
      } else {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";
$font-size-sm: 12px;
$font-size-xs: 10px;

.z-textarea {
  position: relative;
  border-radius: 6px;

  textarea {
    display: block;
    line-height: 2.4em;
    margin: 0;
    padding-left: 16px;
    width: 100%;
    background-color: $white;
    border: 1px solid $link-water;
    border-radius: 6px;
    font-weight: 500;
    box-sizing: border-box;
    color: $comet;
    font-size: $font-size-sm;
  }

  .disabled {
    background-color: $solitude-100;
    color: $comet;
  }

  textarea:focus {
    border: 1px solid $zubut-blue;
    border-radius: 6px;
    box-shadow: 0px 0px 2px rgba($zubut-blue, 0.25);
  }

  label.animated {
    display: block;
    position: absolute;
    bottom: 1.9rem;
    color: $comet;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    left: 12px;
    top: 6px;
    font-size: $font-size-sm;
    padding-left: 4px;
    padding-right: 4px;
    font-weight: 500;
    pointer-events: none;
  }

  label.static {
    position: absolute;
    top: -24px;
    font-weight: 500;
    font-size: 12px;
  }

  &.z-textarea-label-static {
    margin-top: 40px;
  }
}

/* Float label when is focused */
.z-textarea textarea:focus + label.animated,
.z-textarea.z-textarea-has-value textarea + label.animated {
  z-index: 2;
  font-size: $font-size-xs;
  transform: translateY(-13px);
  transition: 0.2s ease-in-out transform;

  &:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: $white;
    left: 0px;
    top: 7px;
    z-index: -1;
    padding-left: 1px;
  }
}

/* Color label when is focused */
.z-textarea textarea:focus + label.animated {
  color: $zubut-blue;
}

/* Append icon styles */
.z-textarea.z-textarea-append-icon {
  textarea {
    padding-right: 38px;
  }

  .append-icon {
    position: absolute;
    display: block;
    right: $font-size-xs;
    top: 6px;
  }
}

.z-textarea.z-textarea-prepend-icon {
  textarea {
    padding-left: 38px;
  }

  .prepend-icon {
    position: absolute;
    display: block;
    left: $font-size-xs;
    top: 6px;
  }
}

/* State validation styles */
.z-textarea.z-textarea-append-icon.z-textarea-valid {
  textarea {
    padding-right: 68px;
  }

  .state-icon {
    position: absolute;
    display: block;
    right: 40px;
    top: 6px;
  }
}

.z-textarea.z-textarea-prepend-icon.z-textarea-valid {
  textarea {
    padding-right: 38px;
  }

  .state-icon {
    position: absolute;
    display: block;
    right: $font-size-xs;
    top: 6px;
  }
}

.z-textarea.z-textarea-valid:not(.z-textarea-append-icon, .z-textarea-prepend-icon) {
  textarea {
    padding-right: 38px;
  }

  .state-icon {
    position: absolute;
    display: block;
    right: $font-size-xs;
    top: 6px;
  }
}

.z-textarea.z-textarea-invalid {
  textarea {
    border: 1px solid $medium-carmine;
  }

  textarea:focus {
    border: 1px solid $medium-carmine;
    border-radius: 6px;
    box-shadow: 0 0 0 2px rgba($medium-carmine, 0.05);
  }

  textarea:focus + label {
    color: $medium-carmine;
  }
}

.word-count {
  position: absolute;
  z-index: 1;
  right: 10px;
  bottom: 10px;
  font-size: 0.8em;
  color: $dim-gray;
}
</style>
